<template xmlns:v-bind="http://www.w3.org/1999/xhtml">
           <v-container fluid fill-height class="loginOverlay">
          <v-layout flex align-center justify-center>

            <v-flex xs12 sm4 elevation-6>
                    <v-img
                      max-height="400"
                      max-width="600"

                      :src="require('../assets/logoblanco.png')">
                   </v-img>
              <v-toolbar class="pt-5 primary">
                <v-toolbar-title class="white--text"><h4>BIENVENIDO</h4></v-toolbar-title>
              </v-toolbar>
              <v-card>
                <v-card-text class="pt-4">
                  <div>
                      <v-form v-model="valid" ref="form">
                        <v-text-field
                          label="Usuario"
                          v-model="signInDetails.username"
                          required
                        ></v-text-field>
                        <v-text-field
                          label="Contrasena"
                            :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                            :rules="[rules.required, rules.min]"
                            :type="show3 ? 'text' : 'password'"
                            name="input-10-2"
                            hint="At least 8 characters"
                            class="input-group--focused"
                            @click:append="show3 = !show3"
                            v-model="signInDetails.password"
                            min="4"
                            required
                        ></v-text-field>
                        <v-layout justify-space-between>
                            <v-btn @click="userSignIn" :class=" { 'primary white--text' : valid, disabled: !valid }">Entrar</v-btn>
                            <a href="">Contrasena Olvidada</a>
                        </v-layout>
                      </v-form>
                  </div>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
       </v-container>
</template>

<script>
import { useUserStore } from "@/stores/user";
import { USER_SINGIN } from '../constants/graphql'
import {computed} from "vue";

export default {
  name: "SingIn",

  setup() {
    const userStore = useUserStore();
    const info  = computed(() => userStore.getUser);
    return { userStore, info };
  },

  data() {
    return {
      groups:[],
      idAdmin:false,
      registerActive: false,
      emailReg: "",
      passwordReg: "",
      confirmReg: "",
      emptyFields: false,
      signInDetails: {
        username: "",
        password: "",
      },
      valid: false,
      e1: false,
      show3: false,
        rules: {
                  required: value => !!value || 'Required.',
                  min: v => v.length >= 8 || 'Min 8 characters',
                  emailMatch: () => (`The email and password you entered don't match`),
                },
    };
  },
   async mounted () {

    },
  methods: {
    async userSignIn() {
      await this.$apollo.mutate({
        mutation: USER_SINGIN,
        variables: {
          username: this.signInDetails.username,
          password: this.signInDetails.password,
        },
      }).then((data)=>{
        if(data.data.tokenAuth.token){
             this.userStore.setToken(data.data.tokenAuth.token);
             this.userStore.setUser(data.data.tokenAuth.user);
             console.log(data.data.tokenAuth.user.groups)
             this.groups = data.data.tokenAuth.user.groups
             if(this.groups.filter(group => group.id == 1).length > 0)
                  {
                    this.isAdmin = true
                  }
                  else{
                    this.isAdmin = false
                  }
            console.log("isAdmin: "+this.isAdmin)
             if(this.isAdmin)
                 this.$router.push('/fastItin')
             else
                 this.$router.push('/sellerItin')
          }
          else
             alert("Usuario o contrasena incorrecta")
      }).catch((error) => {
              // Error
              console.error("Este es el Error: "+error)
              alert("Usuario o contrasenha incorrecta")
              // We restore the initial user input
            });

    },
  },
};
</script>
